import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import styled from "styled-components"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import LayoutNew from "../components/layout-new"
import Seo from "../components/seo"

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-area: 1/1;
  object-fit: cover;
  height: 400px;

  @media (max-width:1023px) {
    height: 300px;
  }
  @media (max-width:767px) {
    height: 150px;
  }
`

const pStyle = "text-base mb-6"

const PageTeam = () => {
  const data = useStaticQuery(graphql`
    query PageTeamQuery {
      prismicTeamPage {
        _previewable
        data {
          team_page_title
          team_page_short_description
          team_page_hero_background {
            gatsbyImageData(
              width: 1920
            )
          }
          red_bubble_text
          team_bio_section {
            name
            position
            short_bio
            email_address
            section_background
            photo {
              alt
              gatsbyImageData(
                width: 367
              )
            }
          }
        }
      }
      photoPlaceholder: file(relativePath: { eq: "team/team-photo-placeholder.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 367
          )
        }
      }
      imgHero: file(relativePath: { eq: "team/sra-team-cropped.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const doc = data.prismicTeamPage
  const imgHero = getImage(doc.data.team_page_hero_background)
  const imgSrc = getSrc(doc.data.team_page_hero_background)
  const photoPlaceholder = getImage(data.photoPlaceholder)

  return (
    <LayoutNew>
      <Seo
        title={doc.data.team_page_title}
        description={doc.data.team_page_short_description}
        image={imgSrc}
        noIndex
      />
      <div className="relative -z-10 pt-14 xl:pt-20" style={{ display: "grid", background: "#000000" }}>
        <StyledGatsbyImage
          alt="hero background"
          image={imgHero}
          formats={["auto", "webp", "avif"]}
        />
      </div>

      {doc.data.red_bubble_text
        ?
        <div>
          <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto py-12 px-6 md:px-16 -mt-8 md:-mt-16 z-20 rounded-3xl shadow-lg">
            <h2 className="text-white font-medium text-center text-lg md:text-xl">{doc.data.red_bubble_text}</h2>
          </div>
        </div>
        :
        null
      }

      {doc.data.team_bio_section.map(item => {
        const teamPhoto = getImage(item.photo)
        return (
          <div className={`${item.section_background ? "bg-gray-100" : "bg-white"} pt-16 md:pt-24 pb-12 md:pb-24`}>
            <div className="relative w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-lg flex flex-col xl:flex-row items-center mx-auto xl:space-x-6">
              <div className="w-full xl:w-4/12 flex justify-center p-6">
                <GatsbyImage
                  image={teamPhoto ? teamPhoto : photoPlaceholder}
                  alt={item.photo.alt ? item.photo.alt : "team photo"}
                  className="rounded-lg shadow-lg"
                />
              </div>
              <div className="w-full xl:w-8/12">
                <div className="mb-2">
                  <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">{item.name ? item.name : "Team Name"}</h2>
                </div>
                <div className="lg:w-8/12 mx-auto border-t-2 border-site-gray-dark pt-2 mb-8">
                  <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">{item.position ? item.position : "Position"}</h3>
                </div>

                <p className={pStyle}>{item.short_bio ? item.short_bio : "Short bio should go here."}</p>
                
                <div className="flex items-start mt-4">
                  <Link to="/contact">
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-sm text-white uppercase tracking-wider px-6 py-2 mb-6">Send an Email</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </LayoutNew>
  )
}

export default withPrismicPreview(PageTeam)
